// npm
import React, { memo } from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import {
  Box,
  BoxProps,
  Container,
  Palette,
  Stack,
  styled,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import loadable from '@loadable/component'

// components
const CmsLinkButton = loadable(() => import('@atoms/buttons/CmsLinkButton'))
const TextContent = loadable(() => import('@molecules/text/TextContent'))
const QuoteContent = loadable(
  () => import('@molecules/sections/content/QuoteContent')
)
const EnquiryForm = loadable(() => import('@organisms/forms/EnquiryForm'))

// constants
import { BreakpointProps } from '@constants/breakpointProps'
import { firstSectionSpacing } from '@constants/firstSectionSpacing'

// helpers
import getThemeColorValue from '@helpers/get-theme-color-value'

interface BgProps {
  bgColor: string
}

export interface SectionContentImageProps extends BgProps, BoxProps {
  sectionContent: Queries.DatoCmsTextContent | Queries.DatoCmsQuoteContent
  imageOpacity?: number
  xsImage: IGatsbyImageData
  mdImage: IGatsbyImageData
  lgImage: IGatsbyImageData
  alternateLayout?: boolean
  mobileImageVariant?: string
  isFirstSection?: boolean
}

interface StyledGatsbyImageProps extends BreakpointProps {
  imageOpacity?: number
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}))

const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<BgProps>(({ bgColor, theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  backgroundColor: getThemeColorValue(bgColor as keyof Palette),
}))

const StyledGatsbyImage = styled(GatsbyImage, {
  shouldForwardProp: (prop) => prop !== 'breakpoint' && prop !== 'imageOpacity',
})<StyledGatsbyImageProps>(({ breakpoint, imageOpacity, theme }) => ({
  width: '100%',
  height: '100%',
  ...(imageOpacity && {
    opacity: imageOpacity,
  }),
  [theme.breakpoints.up(breakpoint)]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%,0)',
  },
  [theme.breakpoints.down('md')]: {
    height: '0 !important',
    paddingTop: '75%',
  },
  [theme.breakpoints.down('sm')]: {
    height: '0 !important',
    paddingTop: '100%',
  },
}))

const SectionContentImage = ({
  sectionContent,
  imageOpacity,
  xsImage,
  mdImage,
  lgImage,
  alternateLayout,
  bgColor,
  mobileImageVariant,
  isFirstSection,
  ...props
}: SectionContentImageProps) => {
  if (sectionContent) {
    const theme = useTheme()
    const breakpoint = 'md'
    let contentComponent
    switch (sectionContent.model.apiKey) {
      case 'text_content':
        const textContent = sectionContent as Queries.DatoCmsTextContent
        contentComponent = (
          <>
            <TextContent
              smallHeading={textContent.smallHeading}
              mainHeading={textContent.mainHeading}
              leadText={textContent.leadText}
              text={textContent.textNode.childMarkdownRemark.html}
              mb={{ xs: 4, sm: 5, md: 6 }}
              mt={isFirstSection ? { ...firstSectionSpacing, lg: 6, xl: 6 } : 0}
              excludeMainHeadingDecorator={
                textContent.excludeMainHeadingDecorator
              }
            />
            {textContent.button && textContent.button[0] && (
              <CmsLinkButton button={textContent.button[0]} />
            )}
            {textContent.includeEnquiryForm && <EnquiryForm />}
          </>
        )
        break
      case 'quote_content':
        const quoteContent = sectionContent as Queries.DatoCmsQuoteContent
        const q = quoteContent.quote as Queries.DatoCmsQuote
        contentComponent = <QuoteContent d={q} />
        break
    }

    // @ts-ignore
    const images = withArtDirection(getImage(lgImage), [
      {
        media: `(max-width: ${theme.breakpoints.values.sm}px)`,
        image: getImage(xsImage),
      },
      {
        media: `(max-width: ${theme.breakpoints.values.md}px)`,
        image: getImage(mdImage),
      },
    ])

    return (
      <Wrapper
        component="section"
        overflow="hidden"
        bgcolor={bgColor}
        {...props}
      >
        <Stack sx={{ display: { xs: 'flex', [breakpoint]: 'block' } }}>
          <Grid
            container
            spacing={0}
            sx={{
              position: {
                xs: mobileImageVariant === 'bg' ? 'absolute' : 'relative',
                [breakpoint]: 'absolute',
              },
              order: mobileImageVariant === 'bottom' ? 2 : 1,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Grid
              xs={12}
              md={8}
              xl={7}
              ml={alternateLayout ? 0 : 'auto'}
              sx={{
                minHeight: {
                  xs: mobileImageVariant === 'bg' ? '100vh' : 'auto',
                  [breakpoint]: '100vh',
                },
              }}
            >
              <ImageWrapper bgColor={bgColor}>
                <StyledGatsbyImage
                  breakpoint={breakpoint}
                  imageOpacity={imageOpacity}
                  image={images}
                  alt=""
                  sx={{
                    position: {
                      xs: mobileImageVariant === 'bg' ? 'static' : 'relative',
                      [breakpoint]: 'relative',
                    },
                  }}
                />
              </ImageWrapper>
            </Grid>
          </Grid>
          <Container
            maxWidth="xl"
            sx={{
              position: 'relative',
              order: mobileImageVariant === 'bottom' ? 1 : 2,
              pt: isFirstSection
                ? 0
                : mobileImageVariant === 'bg'
                ? 0
                : { xs: 6, sm: 7, md: 8 },
              pb: mobileImageVariant === 'bg' ? 0 : { xs: 6, sm: 7, md: 8 },
            }}
          >
            <Grid container spacing={0}>
              <Grid
                xs={12}
                {...{ [breakpoint]: 6 }}
                ml={alternateLayout ? 'auto' : 0}
                sx={{
                  minHeight: {
                    xs: mobileImageVariant === 'bg' ? '100vh' : 'auto',
                    [breakpoint]: '100vh',
                  },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box>{contentComponent}</Box>
              </Grid>
            </Grid>
          </Container>
        </Stack>
      </Wrapper>
    )
  }
  return <></>
}

export default memo(SectionContentImage)
